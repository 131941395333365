import React from "react";
import Layout from "../components/layout";
import EmpresaBanner from "../images/Empresa.jpg";
import SEO from "../components/seo";

function Empresa() {
  return (
    <Layout>
      <SEO
        keywords={[`Golden`, `Sondagens`, `SPT`, `Construção`]}
        title="Empresa"
      />

      <section
        style={{
          height: "300px",
          backgroundImage: `linear-gradient(to right, hsla(0, 0%, 0%, 1), hsla(0, 0%, 0%, 0.3)), url(${EmpresaBanner})`,
          backgroundSize: "cover",
          backgroundPosition: "bottom",
          backgroundRepeat: "no-repeat",
          backgroundAttachment: "fixed",
        }}
      >
        <h1 className="font-bold text-white ml-8 mt-24 uppercase base-title-golden">
          A empresa
        </h1>
      </section>
      <h2 className="font-bold text-center base-golden-center mt-12">
        Quem somos
      </h2>
      <div className="text-justify my-12 md:pr-12 mx-6 md:mx-24 xl:mx-48">
        <h3>
          Somos uma empresa que atua nas diversas áreas da construção civil, com
          profissionais capacitados e prontos para atender a sua necessidade.{" "}
        </h3>
        <h3 className="my-10">
          Oferecemos serviços de sondagens, execução de obras, realizamos
          projetos estruturais, arquitetônicos, elétricos, hidrossanitários e
          preventivos. Buscamos sempre atender a necessidade do cliente junto
          com a otimização do projeto em conjunto com a execução.
        </h3>
        <h3>
          A experiência e capacitação da nossa equipe busca em todas as suas
          atuações atender a necessidade do cliente trazendo o menor custo
          aliado com melhor desempenho para o mesmo.
        </h3>
      </div>
      <h2 className="font-bold text-center base-golden-center mt-20">
        Nossos valores
      </h2>
      <div className="grid grid-cols-1 md:grid-cols-4 gap-8 mx-6 md:mx-12 xl:mx-20 my-16">
        <div>
          <h3 className="font-bold">Honestidade</h3>
          <p>
            Honestidade é a base que remete dignidade e honra. Verdade em todas
            as ações e atitudes
          </p>
        </div>
        <div>
          <h3 className="font-bold">Equilíbrio</h3>
          <p>
            Equilíbrio é ter estabilidade, distribuindo de forma harmônica
            nossos desafios diários
          </p>
        </div>
        <div>
          <h3 className="font-bold">Trabalho em equipe</h3>
          <p>
            O trabalho em equipe é essencial para o sucesso da corporação e do
            profissional
          </p>
        </div>
        <div>
          <h3 className="font-bold">Organização</h3>
          <p>
            Através de planejamento, a execução de um projeto terá mais
            produtividade e qualidade
          </p>
        </div>
        <div>
          <h3 className="font-bold">Compromisso</h3>
          <p>
            Remete uma conexão institucional entre uma pessoa e uma organização
          </p>
        </div>
        <div>
          <h3 className="font-bold">Disciplina</h3>
          <p>Gerenciar atividades tendo foco no objetivo final</p>
        </div>
        <div>
          <h3 className="font-bold">Competitividade</h3>
          <p>
            Buscar sempre o melhor para alcançar rapidamente o objetivo e
            melhores resultados
          </p>
        </div>
        <div>
          <h3 className="font-bold">Inovação</h3>
          <p>É a exploração com sucesso na busca do inesperado</p>
        </div>
      </div>
    </Layout>
  );
}

export default Empresa;
